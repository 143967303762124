import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_d = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - D'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>D</h2>
     <LatQuotesIntro />
    
     <p><b>Da mihi basia mille</b> - Kiss me with a thousand kisses</p>
<p><b>Da mihi castitatem et continentiam, sed noli modo!</b> - Make me chaste and pure, but not yet!</p>
<p><b>Damnant quod non intellegunt</b> - They condemn what they do not understand</p>
<p><b>Data et accepta</b> - Expenditure and receipts</p>
<p><b>De asini umbra disceptare</b> - To argue about the shadow of an ass. (petty things for petty mind)</p>
<p><b>De bene esse</b> - It shall be so, as long as it is well</p>
<p><b>De die in diem</b> - From day to day</p>
<p><b>De duobus malis, minus est semper eligendum</b> - Of two evils, the lesser must always be chosen (Thomas a Kempis)</p>
<p><b>De facto</b> - Something that is automatically accepted</p>
<p><b>De gustibus non est disputandum</b> - There's no accounting for taste</p>
<p><b>De integro</b> - Repeat again from the start</p>
<p><b>De iure</b> - By law. According to law</p>
<p><b>De minimis non curat praetor</b> - The authority or king, or law does not care about trivial things</p>
<p><b>De minimis</b> - With respect to trifles</p>
<p><b>De mortuis nil nisi bonum</b> - Say nothing but good about the dead. (Chilon)</p>
<p><b>De nihilo nihil</b> - Nothing comes from nothing. (Lucretius)</p>
<p><b>De novo</b> - Anew</p>
<p><b>De profundis</b> - Up from the depths (of misery)</p>
<p><b>De rerum natvra</b> - On the nature of things. (title of Marcus Aurelius's magnum opus)</p>
<p><b>Decrevi</b> - I have decreed</p>
<p><b>Dei gratia</b> - By the grace of God</p>
<p><b>Delenda est carthago</b> - Carthage must be destroyed</p>
<p><b>Dente lupus, cornu taurus petit</b> - The wolf attacks with his fang, the bull with his horn. (Horace)</p>
<p><b>Deo adiuvante</b> - With God's help</p>
<p><b>Deo favente</b> - With God's favour</p>
<p><b>Deo gratias</b> - [We give] thanks to God</p>
<p><b>Deo Optimo Maximo</b> - To God, the Best, the Greatest</p>
<p><b>Deo vindice</b> - God our defender. (motto of the Confederate States of America)</p>
<p><b>Deo volente</b> - God willing</p>
<p><b>Desunt cetera</b> - The rest is missing</p>
<p><b>Deus absconditus</b> - A god who is hidden from man</p>
<p><b>Deus commodo muto consisto quem meus canis sententia existo</b> - Which, in a very ham fisted way, with generosity, comes close to being</p>
<p><b>Deus et natua non faciunt frusta</b> - God and nature do not work together in vain</p>
<p><b>Deus ex machina</b> - A contrived or artificial solution. (literally, 'a god from a machine')</p>
<p><b>Deus misereatur</b> - May God Have Mercy</p>
<p><b>Deus vobiscum</b> - God be with you</p>
<p><b>Deus volent</b> - (as) God will</p>
<p><b>Deus vult!</b> - God wills it! (Slogan of the Crusades)</p>
<p><b>Dictum sapienti sat est</b> - A word to a wise person is sufficient</p>
<p><b>Dies Irae</b> - Day of Wrath, or Judgment Day</p>
<p><b>Dies natalis</b> - Birthday</p>
<p><b>Difficile est longum subito deponere amorem</b> - It is difficult to suddenly give up a long love. (Catullus)</p>
<p><b>Difficile est saturam non scribere</b> - It is hard not to write satire. (Juvenalis)</p>
<p><b>Difficile est tenere quae acceperis nisi exerceas</b> - It is difficult to retain what you may have learned unless you should practice it. (Pliny the Younger)</p>
<p><b>Diis aliter visum</b> - The Gods decided otherwise</p>
<p><b>Diligentia maximum etiam mediocris ingeni subsidium</b> - Diligence is a very great help even to a mediocre intelligence. (Seneca)</p>
<p><b>Diligite justitiam, o judices terrae</b> - Cherish justice, o judges of the earth</p>
<p><b>Dimidium facti qui coepit habet</b> - Half is done when the beginning is done. (Horace)</p>
<p><b>Dira necessitas</b> - The dire necessity. (Horace)</p>
<p><b>Discere docendo</b> - To learn through teaching</p>
<p><b>Disiecti membra poetae</b> - Limbs of a dismembered poet. (Horace)</p>
<p><b>Disjecta membra</b> - The scattered remains</p>
<p><b>Divide et impera</b> - Divide and conquer</p>
<p><b>Dixi</b> - I have spoken. (I will say no more on the matter, and no one else may speak further)</p>
<p><b>Do ut des</b> - I give so that you give back</p>
<p><b>Docendo discitur</b> - It is learned by teaching. (Seneca)</p>
<p><b>Doli capax</b> - Capable of crime</p>
<p><b>Domine, dirige nos</b> - Lord, direct us</p>
<p><b>Domino optimo maximo</b> - To the Lord, the best and greatest</p>
<p><b>Dominus illuminatio mea</b> - The Lord is my light</p>
<p><b>Dominus providebit</b> - The Lord will provide</p>
<p><b>Dominus tecum</b> - May the Lord be with you (Singular)</p>
<p><b>Dominus vobiscum</b> - May the Lord be with you (Plural)</p>
<p><b>Donec eris felix, multos numerabis amicos</b> - As long as you are fortunate, you will have many friends (when you are successful, everyone wants to be your friend)</p>
<p><b>Donna nobis pacem</b> - Grant us peace</p>
<p><b>Draco dormiens nunquam titillandus</b> - Never Tickle a Sleeping Dragon. (motto of Harry Potter's alma mater)</p>
<p><b>Dramatis personae</b> - Characters of the play</p>
<p><b>Dulce bellum inexpertis</b> - War is sweet for those who haven't experienced it. (Pindaros)</p>
<p><b>Dulce est desipere in loco</b> - It is sweet to relax at the proper time</p>
<p><b>Dulce et decorum est pro patria mori</b> - It is sweet and glorious to die for one's country. (Horace)</p>
<p><b>Dulcius ex asperis</b> - Through difficulty, sweetness</p>
<p><b>Dum excusare credis, accusas</b> - When you believe you are excusing yourself, you are accusing yourself. (St. Jerome)</p>
<p><b>Dum inter homines sumus, colamus humanitatem</b> - As long as we are among humans, let us be humane. (Seneca)</p>
<p><b>Dum spiramus tuebimur</b> - While we breathe, we shall defend</p>
<p><b>Dum spiro, spero</b> - While I breathe, I hope. (Cicero)</p>
<p><b>Dum tempus habemus, operemur bonum</b> - While we have the time, let us do good</p>
<p><b>Dum vita est spes est</b> - While life is, hope is. / While there is life there is hope</p>
<p><b>Dum vivimus, vivamus</b> - While we live, let us live (Epicurean philosophy)</p>
<p><b>Dura lex, sed lex</b> - The law is harsh, but it is the law</p>

   </Layout>
  )
}

export default LatQuotes_d
